// src/services/api.ts
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: true,
});

export const register = (data: any) => api.post('/auth/register', data);
// Store the token internally in localStorage after successful login
export const login = async (data: any) => {
  try {
    const response = await api.post('/auth/login',
            data, {
                withCredentials: true,
            }
    );
    console.log('Date retrieved back on login:', data)
    // const token = response.data.token;  // Assuming the token is in the response
    // if (token) {
    //   localStorage.setItem('authToken', token);  // Store the token in localStorage
    // }
    return response;
  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
};


// Logout function that removes the token from localStorage
export const logout = async () => {
  try {
    await api.get('/auth/logout');
    // localStorage.removeItem('authToken');  // Remove token on logout
  } catch (error) {
    console.error('Logout failed:', error);
    throw error;
  }
};

// TODO: LATER ON Fetch the current user based on the stored token (JWT etc)
export const getCurrentUser = async () => {
//   const token = localStorage.getItem('authToken');
//   if (!token) {
//     throw new Error('No authentication token found');
//   }

  try {
    // console.log('Cookies before request:', document.cookie);
    console.log('API for getCurrentUser being executed');
    const response = await api.get('/auth/me', 
        {
        //   headers: {
        //     Authorization: `Bearer ${token}`,  // Use the stored token in the request header
        //   },
            withCredentials: true
        }
    );
    console.log('getCurrentUser Response:', response);
    return response ;
    // return response.data;
  } catch (error) {
    // console.error('Fetching current user failed:', error);
    throw error;
  }
};

export const refreshInfraUsers = async () => {
  try {
    await api.get('/infrauser/refresh');
    // await api.get('/infrauser/getall'); // TODO: Need to be enabled only if refresh => does not get reflected automatically...
  } catch (error) {
    // console.error('Refresh InfraUser Failed:', error);
    throw error;
  }
};

export const getInfraUserSummary = async () => {
  try {
    console.log('Executing /infrauser/summary API Get call');
    const response = await api.get('/infrauser/summary');
    console.log('InfraUser Summary Response:', response);
    return response;  // Return the response
  } catch (error) {
    console.error('InfraUser Summary API Failed:', error);
    throw error;
  }
};


export const generateInfraUsersStd = async (formData: FormData) => {
  try {
    const response = await api.post('/infrauser/generatestd',
        formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        }, 
    );
    console.log('Response for generate infrauser STD: ', response);
    return response;
  } catch (error) {
    console.error('Generate InfraUser Std Failed:', error);
    throw error;
  }
};

export default api;
