import { InfraUser } from './types'; 

export const tableColumns: { header: string; key: keyof InfraUser }[] = [
  { header: '#', key: 'sid' },  // Maps to "sid"
//   { header: 'Fetch ID', key: 'FetchId' },  // Maps to "FetchId"
  { header: 'Server Info', key: 'ServerInfo' },  // Maps to "ServerInfo"
  { header: 'Login ID', key: 'UserId' },  // Maps to "UserId"
  { header: 'Numeric ID', key: 'NumericId' },  // Maps to "NumericId"
  { header: 'Enabled', key: 'Enabled' },  // Maps to "Enabled"
  { header: 'Home Directory', key: 'HomeDir' },  // Maps to "HomeDir"
//   { header: 'Home Directory Status', key: 'HomeDirStatus' },  // Maps to "HomeDirStatus"
  { header: 'Shell', key: 'Shell' },  // Maps to "Shell"
//   { header: 'User Details', key: 'UserDetails' },  // Maps to "UserDetails"
  { header: 'Primary Group', key: 'PrimaryGroup' },  // Maps to "PrimaryGroup"
  { header: 'VNC Ports', key: 'VNCPorts' },  // Maps to "VNCPorts"
//   { header: 'Last Login IP', key: 'LastLoginIP' },  // Maps to "LastLoginIP"
//   { header: 'Last Login TTY', key: 'LastLoginTty' },  // Maps to "LastLoginTty"
  { header: 'Email', key: 'Email' },  // Maps to "Email"
//   { header: 'Mobile', key: 'Mobile' },  // Maps to "Mobile"
//   { header: 'User Type', key: 'UserType' },  // Maps to "UserType"
//   { header: 'System Type', key: 'SystemType' },  // Maps to "SystemType"
//   { header: 'Move Location', key: 'MoveLocation' },  // Maps to "MoveLocation"
//   { header: 'Password Inactive', key: 'PasswordInactive' },  // Maps to "PasswordInactive"
//   { header: 'Min Days Between Change', key: 'MinDaysBetweenChange' },  // Maps to "MinDaysBetweenChange"
//   { header: 'Max Days Between Change', key: 'MaxDaysBetweenChange' },  // Maps to "MaxDaysBetweenChange"
//   { header: 'Days Before Expiry Warning', key: 'DaysBeforeExpiryWarning' },  // Maps to "DaysBeforeExpiryWarning"
//   { header: 'Password Change Required', key: 'PasswordChangeRequired' },  // Maps to "PasswordChangeRequired"
//   { header: 'Remote Login Entries', key: 'RemoteLoginEntries' },  // Maps to "RemoteLoginEntries"
//   { header: 'Remarks', key: 'Remarks' },  // Maps to "Remarks"
//   { header: 'Login Expiry', key: 'LoginExpiry' },  // Maps to "LoginExpiry"
//   { header: 'Password Expiry', key: 'PasswordExpiry' },  // Maps to "PasswordExpiry"
  { header: 'Account Expiry', key: 'AccountExpiry' },  // Maps to "AccountExpiry"
//   { header: 'Setup Date', key: 'SetupDate' },  // Maps to "SetupDate"
  { header: 'Last Updated', key: 'LastUpdated' },  // Maps to "LastUpdated"
  { header: 'Last Login Time', key: 'LastLoginTime' },  // Maps to "LastLoginTime"
//   { header: 'Last Password Change', key: 'LastPasswordChange' },  // Maps to "LastPasswordChange"
//   { header: 'Last Login', key: 'LastLogin' },  // Maps to "LastLogin"
//   { header: 'Created At', key: 'createdAt' },  // Maps to "createdAt"
//   { header: 'Updated At', key: 'updatedAt' },  // Maps to "updatedAt"
];
