// store/userTableSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InfraUser } from '../types';

interface UserTableState {
  selectedRows: string[];
  filter: string;
  columnFilters: { [key: string]: string };
  sortConfig: { key: keyof InfraUser; direction: 'ascending' | 'descending' } | null;
  filterDropdown: string | null; // Add filterDropdown to state
}

const initialState: UserTableState = {
  selectedRows: [],
  filter: '',
  columnFilters: {},
  sortConfig: null,
  filterDropdown: null, // Initialize filterDropdown
};

const userTableSlice = createSlice({
  name: 'userTable',
  initialState,
  reducers: {
    toggleRowSelection(state, action: PayloadAction<string>) {
      const loginID = action.payload;
      if (state.selectedRows.includes(loginID)) {
        state.selectedRows = state.selectedRows.filter(id => id !== loginID);
      } else {
        state.selectedRows.push(loginID);
      }
    },
    setFilter(state, action: PayloadAction<string>) {
      state.filter = action.payload;
    },
    clearSelection(state) {
      state.selectedRows = [];
    },
    selectAllRows(state, action: PayloadAction<string[]>) {
      state.selectedRows = action.payload;
    },
    setColumnFilter(state, action: PayloadAction<{ key: keyof InfraUser; value: string }>) {
      const { key, value } = action.payload;
      state.columnFilters[key] = value;
    },
    setSortConfig(state, action: PayloadAction<{ key: keyof InfraUser; direction: 'ascending' | 'descending' } | null>) {
      state.sortConfig = action.payload;
    },
    setFilterDropdown(state, action: PayloadAction<string | null>) {
      state.filterDropdown = action.payload; // Add reducer for filterDropdown
    },
  },
});

export const {
  toggleRowSelection,
  setFilter,
  clearSelection,
  selectAllRows,
  setColumnFilter,
  setSortConfig,
  setFilterDropdown, // Export the new action
} = userTableSlice.actions;

export default userTableSlice.reducer;
