// store/userGenerationSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserGenerationState {
  excelData: any[][] | null;
  status: string;
  fileUploaded: boolean;
  fileProcessed: boolean;
}

const initialState: UserGenerationState = {
  excelData: null,
  status: 'Please select a file.',
  fileUploaded: false,
  fileProcessed: false,
};

const userGenerationSlice = createSlice({
  name: 'userGeneration',
  initialState,
  reducers: {
    setExcelData(state, action: PayloadAction<any[][] | null>) {
      state.excelData = action.payload;
    },
    setStatus(state, action: PayloadAction<string>) {
      state.status = action.payload;
    },
    setFileUploaded(state, action: PayloadAction<boolean>) {
      state.fileUploaded = action.payload;
    },
    setFileProcessed(state, action: PayloadAction<boolean>) {
      state.fileProcessed = action.payload;
    },
    resetState(state) {
      state.excelData = null;
      state.status = 'Please select a file.';
      state.fileUploaded = false;
      state.fileProcessed = false;
    },
  },
});

export const {
  setExcelData,
  setStatus,
  setFileUploaded,
  setFileProcessed,
  resetState,
} = userGenerationSlice.actions;

export default userGenerationSlice.reducer;
