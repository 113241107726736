// src/redux/authSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logout } from '../services/api';
import { AppDispatch, RootState } from './index';
import { ThunkAction } from 'redux-thunk';

interface UserState {
  user: any;
}

// // Check if user data exists in localStorage and initialize state
// const storedUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string) : null;

const initialState: UserState = {
  user: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      // Persist user to localStorage
      localStorage.setItem('user', JSON.stringify(action.payload));
    },
    logoutUser: (state) => {
    //   logout(); // backend logout No longer required here
      state.user = null;
      // Remove user from localStorage on logout
      localStorage.removeItem('user');
    },

    initializeUserFromLocalStorage: (state) => {
      const storedUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string) : null;
      if (storedUser) {
        state.user = storedUser;
      }
    },

  },
});

// Define the performLogout thunk action type
export const performLogout = (): ThunkAction<void, RootState, any, PayloadAction<void>> => async (
  dispatch: AppDispatch
) => {
  try {
    await logout(); // Call the API to log out
    dispatch(logoutUser()); // Dispatch the logout action
  } catch (error) {
    console.error('Logout failed:', error);
  }
};

export const { setUser, logoutUser, initializeUserFromLocalStorage } = authSlice.actions;
export default authSlice.reducer;
