import React, { useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import { RootState, AppDispatch } from '../store';
import {
  setExcelData,
  setStatus,
  setFileUploaded,
  setFileProcessed,
  resetState,
} from '../store/userGenerationSlice';
import translations from '../locales/translations';
import { generateInfraUsersStd } from '../services/api';
import { useDropzone } from 'react-dropzone';

interface Result {
    id: string;
    status: string;
}

interface ResponseData {
    message: string;
    results: Result[];
}

const UserGenerationPage: React.FC = () => {
  const [inputXlsFile, setInputXlsFile] = useState<File | null>(null); // State to store the file
  const dispatch = useDispatch<AppDispatch>();
  const { excelData, status, fileUploaded, fileProcessed } = useSelector(
    (state: RootState) => state.userGeneration
  );
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [statusMessage, setStatusMessage] = useState<string | null>(null);
  const [statusColor, setStatusColor] = useState<'blue' | 'red' | 'orange' | null>(null);
  const [operationResults, setOperationResults] = useState<string | null>(null);

  const updateStatusMessageToError = async (message:string) => {
            setStatusMessage(message);
            setStatusColor('red');
    };
  const updateStatusMessageToInfo = async (message:string) => {
            setStatusMessage(message);
            setStatusColor('blue');
    };
  const updateStatusMessageToOperationInProgress = async (message:string) => {
            setStatusMessage(message);
            setStatusColor('orange');
    };
    const formatOperationResults = (data: ResponseData): string => {
        const { message, results } = data;
        let formattedResults = `${message}\n\n`;

        results.forEach(result => {
            formattedResults += `ID: ${result.id}, Status: ${result.status}\n`;
        });

        // Replace <br /> with newlines
        formattedResults = formattedResults.replace(/<br\s*\/?>/gi, '\n');

        return formattedResults;
    };


//   var inputXlsFile: any ; 

  const onDrop = useCallback((acceptedFiles: File[]) => {
    // Handle the selected file(s)
    console.log(acceptedFiles);
    const file = acceptedFiles[0];
    setInputXlsFile(file); // Store the file in state
 
    // Process the file here (this could replace your handleFileUpload logic)
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json<any[][]>(worksheet, { header: 1 });
        setExcelData(json);
        dispatch(setExcelData(json));
        dispatch(setStatus(translations.en.reviewingByUser));
        dispatch(setFileUploaded(true));
        dispatch(setFileProcessed(false));
        console.log('Dropped file:', file);
      };
      reader.readAsArrayBuffer(file);
    }
  }, [dispatch]);

  // Define accepted file types correctly for TypeScript
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
  });

  const handleGenerateUsers = async () => {
    console.log('Generating users from the Excel data:', excelData);
    updateStatusMessageToOperationInProgress('Generate InfraUsers Operation in progress.... Wait...');
    try {
        if (!inputXlsFile) {
            console.error("NO file available for upload!")
            throw new Error("NO file available for upload!")
        }
        const formData = new FormData();
        formData.append('file', inputXlsFile); // Assume single file upload
        dispatch(setStatus(translations.en.genOprnWIP));
        const response = await generateInfraUsersStd(formData);
        const strResults = JSON.stringify(response.data.message);
        const outputFile = response.data.outfile ;
        updateStatusMessageToInfo(`Generate InfraUser completed! => ` + strResults);
        dispatch(setStatus(translations.en.genOprnCompleted));
        dispatch(setFileProcessed(true));
    } catch(error) {
        const err = error as Error ;
        console.log("Failure in Generating InfraUsers Std template => " + err.message);
        updateStatusMessageToError('Generate InfraUser operation FAILED! check results => ' + err.message);
    }
  };

  return (
    <div className="mx-auto p-4" style={{ fontFamily: "'Roboto', sans-serif", backgroundColor: "#f4f6f8" }}>
      <h1 className="text-3xl font-semibold text-gray-800 mb-4">{translations.en.userGenerationPageTitle}</h1>

    <div {...getRootProps()} style={dropzoneStyle}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here...</p>
      ) : (
        <p>Drag 'n' drop an .xlsx file, or click to select one</p>
      )}
    </div>

        <div className="space-x-4">
            {statusMessage && (
                <p style={{ color: statusColor === 'blue' ? 'blue' : 'red' }}>
                    {statusMessage}
                </p>
            )}
        </div>


      {excelData && (
        <div className="overflow-x-auto mb-4 max-h-60">
          <table className="min-w-full bg-white border border-gray-300 shadow-sm rounded-lg">
            <thead className="bg-gray-200">
              <tr>
                {excelData[0].map((header, index) => (
                  <th key={index} className="py-3 px-4 border-b text-left font-medium text-gray-800">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {excelData.slice(1).map((row, rowIndex) => (
                <tr key={rowIndex} className="hover:bg-gray-50">
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex} className="py-2 px-4 border-b text-gray-900">
                      {cell !== undefined ? cell : translations.en.nA}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="mb-4 text-sm text-gray-600">
        <strong>{translations.en.status}:</strong> {status}
      </div>

      {fileUploaded && !fileProcessed && (
        <button
          onClick={handleGenerateUsers}
          className="px-4 py-2 bg-green-500 text-white rounded-lg shadow-sm hover:bg-green-600 transition"
        >
          {translations.en.generateUsers}
        </button>
      )}

    </div>
  );
};


// Correctly typing the inline style object
const dropzoneStyle: React.CSSProperties = {
  width: '500px',
  height: '20px',
  border: '2px dashed #888',
  borderRadius: '10px',
  textAlign: 'center', // Explicitly typed as one of the acceptable values
  padding: '20px',
};


export default UserGenerationPage;
