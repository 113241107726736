// src/components/Navbar.tsx
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/index';
import { performLogout } from  '../store/authSlice';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../store'; // Import the typed AppDispatch

const Navbar: React.FC = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Get the image path from the environment variable
  const logoPath = process.env.REACT_APP_PMA_LOGO;


  const dispatch = useDispatch<AppDispatch>(); // Use the typed dispatch
  const handleLogout = async () => {
    toggleDropdown();
    await dispatch(performLogout()); // Now dispatch expects async actions
    navigate('/login');
  };

//   const toggleDropdown = () => {
//     setDropdownOpen(!dropdownOpen);
//   };
  const toggleDropdown = () => setDropdownOpen((prev) => !prev);

  // Close dropdown on clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false); // Close dropdown when clicking outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside); // For mobile touch support

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);


  return (
    <nav className="bg-blue-800 p-4 flex items-center justify-between relative">

      <div
        className="absolute left-0 flex items-center cursor-pointer"
        onClick={() => navigate('/')}
      >
        {logoPath && logoPath != "" ? (
          <img
            src={`/${logoPath}`}
            alt="Home"
            className="w-[12%] h-auto mr-0.5" // Example for responsive sizing
            // className="w-[12%] h-auto mr-2" // Example for responsive sizing
            // className="w-12 h-12 mr-2" // Adjust size as needed
          />
        ) : (
          <span className="text-white text-2xl font-bold">Home</span>
        )}
      </div>


      <div className="text-white text-5xl font-bold mx-auto">{process.env.REACT_APP_PMA_TITLE}</div>

      <div className="text-white relative">
        {user ? (
          <div className="flex items-center">
            <button
              onClick={toggleDropdown}
              className="mt-2 w-48 shadow-lg text-white-700 hover:bg-blue-400 rounded-md z-20"
            >
              <span className="material-icons">{user.email}</span>
            </button>
            {dropdownOpen && (
              <div
                ref={dropdownRef}
                className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-md z-30" // Ensure z-index is higher than the button
              >

                <button
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left truncate"
                  onClick={() => navigate('/my-profile')}
                   style={{ maxWidth: '100%' }} // Ensure it doesn't overflow its container
                >
                  {user.email}
                </button>
                <button
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        ) : (
          <div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;