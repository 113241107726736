// src/components/Login.tsx
import React, { useState, useEffect } from 'react';
import { login } from '../services/api';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, logoutUser } from '../store/authSlice';
import { getCurrentUser   } from '../services/api';
import { User } from '../types';

interface FormData {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await getCurrentUser();
        if (res && res.data) {
            console.log(res.data);
            dispatch(setUser(res.data));
            setCurrentUser(res.data);
        }
      } catch (err) {
        console.log("ERROR during login:", err);
      }
    };
    fetchUser();
  }, [navigate]);

  const [formData, setFormData] = useState<FormData>({ email: '', password: '' });
  const [message, setMessage] = useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const res = await login(formData);
    //   console.log("Response :", res);
    //   console.log('Set-Cookie Header:', res.headers['set-cookie']); // Check if cookie is set
      setMessage(res.data.message);
      dispatch(setUser(res.data.user)); // Dispatch the user to the Redux store
      navigate('/dashboard'); // Redirect to dashboard after successful login
    } catch (err: any) {
        if (!err.response || err.code == "ERR_NETWORK" ) {
          // Network error
          setMessage('Network or server error. Please check your connection and server.');
        } else {
            setMessage(err.response?.data?.message || `Login failed (${err.code})`);
        }
    }
  };

  const handleGoogle = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/google`;
  };

  const handleGithub = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/github`;
  };

  const handleLogout = () => {
    dispatch(logoutUser()); // Logout the current user
    setCurrentUser(null);
    setMessage("Logged out successfully");
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
        <h2 className="text-3xl font-semibold text-center mb-6 text-blue-600">
          {currentUser ? 'Already logged in' : 'Login'}
        </h2>

        {location.search.includes('verified=true') && (
          <p className="text-center text-green-500 mb-4">
            Your email has been verified. Please log in.
          </p>
        )}

        {message && <p className="text-center text-red-500 mb-4">{message}</p>}

        {/* If the user is already logged in, show Relogin view */}
        {currentUser ? (

            <div className="flex flex-col items-center">
                <p className="text-center text-gray-1200 mb-4">
                You are currently logged in as <strong>{currentUser.email}</strong> ({currentUser.method}).
                </p>
                <button
                onClick={() => navigate('/dashboard')}  // Navigate to dashboard
                className="w-full bg-green-600 text-white py-2 rounded-lg hover:bg-green-700 transition duration-300 mb-4"
                >
                Go to Dashboard
                </button>
                <h2 className="text-3xl font-semibold text-center mb-6 text-blue-600">____________</h2>

                <h2 className="text-3xl font-semibold text-center mb-6 text-blue-600">
                {currentUser ? 'Relogin (as other user)' : 'Login'}
                </h2>
                <button
                onClick={handleLogout}
                className="w-full bg-yellow-600 text-white py-2 rounded-lg hover:bg-yellow-700 transition duration-300"
                >
                Logout and re-login
                </button>
            </div>

        ) : (
          // If no user is logged in, show the normal login form
          <>
            <form onSubmit={handleSubmit} className="space-y-4">
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                type="submit"
                className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition duration-300"
              >
                Login
              </button>
            </form>

            <hr className="my-6" />

            <div className="flex justify-between">
              <button
                onClick={handleGoogle}
                className="w-5/12 bg-red-500 text-white py-2 rounded-lg hover:bg-red-600 transition duration-300"
              >
                Google
              </button>
              <button
                onClick={handleGithub}
                className="w-5/12 bg-gray-800 text-white py-2 rounded-lg hover:bg-gray-900 transition duration-300"
              >
                GitHub
              </button>
            </div>

            <p className="mt-6 text-center text-gray-600">
              Don't have an account?{' '}
              <Link to="/register" className="text-blue-600 hover:underline">
                Register
              </Link>
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
