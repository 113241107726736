// src/components/OAuthCallback.tsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const OAuthCallback: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // The backend handles setting the JWT token and redirects
    // So this component can simply redirect to dashboard
    navigate('/dashboard');
  }, [navigate]);

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <p className="text-lg font-semibold text-blue-600">Redirecting...</p>
    </div>
  );
};

export default OAuthCallback;
