import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/index';

const UserProfile: React.FC = () => {
  const user = useSelector((state: RootState) => state.auth.user);

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4 text-center">My profile details </h1>

        <h2 className="font-bold mb-4 text-center">{user? user.email : "(Not logged in)" }</h2>
      
        <div className="text-black relative">
            {user ? (
            <div className="flex items-center">
                <div>
                <span className="material-icons">email: {user.email}</span>
                </div>
                <div>
                <span className="material-icons">Method: {user.method}</span>
                </div>
            </div>
            ) : (
            <div>
                User Not logged on
                (The functionality of this page needs to be enhanced to remain relevant on refresh, include last few user operations, joining date etc)
            </div>
            )}
        </div>

    </div>
  );
};

export default UserProfile;
