import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const VerifyEmail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [message, setMessage] = useState('Verifying...');
    const [redirectIn, setRedirectIn] = useState(null);
    const hasVerified = useRef(false); // Add ref to track if verification has run

    useEffect(() => {
        const verify = async () => {
            // Prevent duplicate execution of the effect in strict mode
            if (hasVerified.current) return;
            hasVerified.current = true;

            const params = new URLSearchParams(location.search);
            const token = params.get('token');
            const email = params.get('email');

            if (!token || !email) {
                setMessage('Invalid verification link.');
                return;
            }

            try {
                const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/verify-email?token=${token}&email=${email}`);
                setMessage(res.data.message);

                if (res.status === 200) {
                    let countdown = 5; // Countdown from 5 seconds
                    setRedirectIn(countdown);
                    const timer = setInterval(() => {
                        countdown -= 1;
                        setRedirectIn(countdown);

                        if (countdown === 0) {
                            clearInterval(timer);
                            navigate('/login');
                        }
                    }, 1000);
                }
            } catch (err) {
                setMessage(err.response?.data?.message || 'Verification failed.');
            }
        };

        verify();
    }, [location.search, navigate]);

    return (
        <div>
            <h2>Email Verification</h2>
            <p>{message}</p>
            {redirectIn !== null && (
                <p>Redirecting to login page in {redirectIn} seconds...</p>
            )}
        </div>
    );
};

export default VerifyEmail;
