import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Navbar from './components/Navbar';
import UserTable from './components/UserTable';
import UserGenerationPage from './components/UserGenerationPage';
import Register from './components/Register';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import OAuthCallback from './components/OAuthCallback';
import VerifyEmail from './components/VerifyEmail';
import { User } from './types';
import UserProfile from './pages/UserProfile';
import { initializeUserFromLocalStorage } from './store/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './store'; // Assuming you have this for accessing global state

var user: User;

const Settings: React.FC = () => <h1 className="text-2xl font-bold mb-4">Settings Page</h1>;

const ProtectedRoute: React.FC<{ element: JSX.Element, user: User | null, loading: boolean }> = ({ element, user, loading }) => {
  if (loading) {
    return <div>Loading...</div>;  // Show a loading indicator while user state is being initialized
  }
  return user ? element : <Navigate to="/login" replace />;
};

const App: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true); // Add loading state to handle asynchronous user initialization
  const user = useSelector((state: RootState) => state.auth.user); // Get user from Redux state

  // Re-load user from localStorage when the app initializes
  useEffect(() => {
    dispatch(initializeUserFromLocalStorage());
    setLoading(false); // Set loading to false once initialization is complete
  }, [dispatch]);

  return (
    <Router>
      <Navbar />
      {/* Add a wrapper with full-width */}
      <div className="w-full min-h-screen px-4"> {/* Add this wrapper */}
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/oauth/callback" element={<OAuthCallback />} />
          <Route path="/verify-email" element={<VerifyEmail />} />

          <Route path="/dashboard" element={<Dashboard />} />

          {/* Protected routes (only accessible if the user is authenticated) */}
          <Route path="/user-table" element={<ProtectedRoute element={<UserTable />} user={user} loading={loading} />} />
          <Route path="/settings" element={<ProtectedRoute element={<Settings />} user={user} loading={loading} />} />
          <Route path="/my-profile" element={<ProtectedRoute element={<UserProfile />} user={user} loading={loading} />} />
          <Route path="/upload-excel" element={<ProtectedRoute element={<UserGenerationPage />} user={user} loading={loading} />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
