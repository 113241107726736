// src/components/Register.tsx
import React, { useState } from 'react';
import { register } from '../services/api';
import { useNavigate, Link } from 'react-router-dom';

interface FormData {
  email: string;
  password: string;
}

const Register: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({ email: '', password: '' });
  const [message, setMessage] = useState<string>('');
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const res = await register(formData);
      setMessage(res.data.message);
      if (res.status === 201) {
        navigate('/login');
      }
    } catch (err: any) {
      setMessage(err.response?.data?.message || 'Registration failed');
    }
  };

  const handleGoogle = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/google`;
  };

  const handleGithub = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/github`;
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
        <h2 className="text-3xl font-semibold text-center mb-6 text-blue-600">Register</h2>
        {message && <p className="text-center text-red-500 mb-4">{message}</p>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition duration-300"
          >
            Register
          </button>
        </form>
        <hr className="my-6" />
        <div className="flex justify-between">
          <button
            onClick={handleGoogle}
            className="w-5/12 bg-red-500 text-white py-2 rounded-lg hover:bg-red-600 transition duration-300"
          >
            Google
          </button>
          <button
            onClick={handleGithub}
            className="w-5/12 bg-gray-800 text-white py-2 rounded-lg hover:bg-gray-900 transition duration-300"
          >
            GitHub
          </button>
        </div>
        <div>
        <p className="mt-6 text-center text-gray-600">
          <Link to="/login" className="text-blue-600 hover:underline">Log into existing account{' '}</Link>
        </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
