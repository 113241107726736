
// src/pages/Dashboard.tsx
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FiMenu, FiX, FiUser, FiUsers } from 'react-icons/fi';
import { getCurrentUser, logout, getInfraUserSummary } from '../services/api';
import { User } from '../types';
import { useDispatch } from 'react-redux';
import { setUser, performLogout } from '../store/authSlice';
import { AppDispatch } from '../store'; // Import the typed AppDispatch

const Dashboard: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [curruser, setCurrUser] = useState<User | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>(); // Use the typed dispatch

  const [infraUserSummary, setInfraUserSummary] = useState<any>(null); // State for the infra user summary
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state


const fetchData = async () => {
  try {
    const [userResponse, infraUserSummaryResponse] = await Promise.all([
      getCurrentUser(),
      getInfraUserSummary()
    ]);
    dispatch(setUser(userResponse.data));
    setCurrUser(userResponse.data);
    setInfraUserSummary(infraUserSummaryResponse.data); // Store the fetched summary in state
    // console.log('Both APIs executed successfully');
    setLoading(false);
  } catch (error) {
    console.error('Error fetching data:', error);
    navigate('/login');
  }
};

useEffect(() => {
  fetchData();
}, [navigate]);



//   const dispatch = useDispatch<AppDispatch>(); // Use the typed dispatch
  const handleLogout = async () => {
    await dispatch(performLogout()); // Now dispatch expects async actions
    navigate('/login');
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

//   if (!curruser) return <div className="text-center mt-10 text-lg text-gray-600">Loading...</div>;

    // Check if data exists before rendering
    if (loading) {
        return <div>Loading...</div>;
    }

  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* Sidebar */}
      <div
        className={`fixed top-20 left-0 h-[calc(100vh-4rem)] bg-blue-900 text-white transition-all duration-300 z-10 ${
          isSidebarOpen ? 'w-3/10' : 'w-16'
        }`}
      >
        {/* Sidebar Header */}
        <div className="flex items-center justify-between p-4">
          <h1 className={`${isSidebarOpen ? 'block' : 'hidden'} text-2xl font-bold`}>Dashboard</h1>
          <button onClick={toggleSidebar} className="focus:outline-none">
            {isSidebarOpen ? (
              <FiX className="text-3xl" /> // Icon when sidebar is open
            ) : (
              <FiMenu className="text-3xl" /> // Icon when sidebar is closed
            )}
          </button>
        </div>

        {/* Sidebar Navigation */}
        <nav className="mt-10">
            <ul>
                <li className="px-4 py-2 hover:bg-blue-800 flex items-center">
                <Link to="/user-table" className="flex items-center">
                    <FiUser className="text-xl" />
                    <span className={`${isSidebarOpen ? 'ml-4' : 'hidden'} text-xl`}>
                    User Table
                    </span>
                </Link>
                </li>
                <li className="px-4 py-2 hover:bg-blue-800 flex items-center">
                <Link to="/upload-excel" className="flex items-center">
                    <FiUsers className="text-xl" />
                    <span className={`${isSidebarOpen ? 'ml-4' : 'hidden'} text-xl`}>
                    Users Generation
                    </span>
                </Link>
                </li>
            </ul>
        </nav>

      </div>

      {/* Main Content */}


      <div
        className={`flex-1 p-6 bg-white transition-all duration-300 ${
          isSidebarOpen ? 'ml-3/10' : 'ml-16'
        }`}
      >
        <h2 className="text-3xl font-bold mb-4">Dashboard Overview</h2>

        <div>
                {curruser ? (
                    <div>
                        <h2>Welcome, {curruser.email}</h2>
                        {/* You can show more user details here */}
                    </div>
                ) : (
                    <h2>User not logged in</h2>
                )}
        </div>

        {/* Below is for testing purpose only */}
        {/* <div>
            <button
            className="mr-4 bg-blue-600 hover:bg-blue-700 text-white py-1 px-3 rounded"
            onClick={() => navigate('/login')}
            >
            Check Login Screen
            </button>
        </div> */}


        <div className="w-full">
          <table className="min-w-full bg-gray-100">
            <thead className="bg-blue-800 text-white">
              <tr>
                <th className="py-2 px-4">Last InfraUser Refresh</th>
                <th className="py-2 px-4">Total Users</th>
                <th className="py-2 px-4">Active Users</th>
                <th className="py-2 px-4">Expired Users</th>
                <th className="py-2 px-4">Users Expiring Next Month</th>
                <th className="py-2 px-4">Last Operation Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>

                    <td className="py-2 px-4 text-center">
                    {infraUserSummary ? infraUserSummary.LatestFetchDate : 'N/A'}
                    </td>

                    <td className="py-2 px-4 text-center">
                        <a href="#/user-table" className="text-blue-500 underline">
                            {infraUserSummary ? infraUserSummary.TotalUsers : 0}
                        </a>
                    </td>
                    <td className="py-2 px-4 text-center">
                    {infraUserSummary ? infraUserSummary.TotalActiveUsers : 0}
                    </td>
                    <td className="py-2 px-4 text-center">
                    {infraUserSummary ? infraUserSummary.TotalExpiredUsers : 0}
                    </td>
                    <td className="py-2 px-4 text-center">
                    {infraUserSummary ? infraUserSummary.TotalUsersExpiringWithin30days : 0}
                    </td>
                    <td className="py-2 px-4 text-center">
                    {infraUserSummary ? infraUserSummary.LatestTxnDate : 'N/A'}
                    </td>

              </tr>
            </tbody>
          </table>
        </div>

        {/* Logout button */}
        <div className="flex justify-center mt-6">
          <button
            onClick={handleLogout}
            className="px-6 py-2 bg-red-500 text-white rounded-lg shadow-md hover:bg-red-600 transition"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
