import { configureStore } from '@reduxjs/toolkit';
import userTableReducer from './userTableSlice';
import userGenerationReducer from './userGenerationSlice';
import authReducer from './authSlice';
import { thunk } from 'redux-thunk'; // Fix this import

export const store = configureStore({
  reducer: {
    userTable: userTableReducer,
    userGeneration: userGenerationReducer,
    auth: authReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
